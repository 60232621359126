<template>
  <detailed-view-container
    questionnaireTitle="Linear Analog Self Assessment (VES)"
    :series="series"
  >
    <template v-slot:questionnnairePanel>
      <VGPQuestionnairePanels
        :questionnaires="questionnaires"
        :questions="questionnaireQuestions"
      />
    </template>
  </detailed-view-container>
</template>

<script>
const components = {
  VGPQuestionnairePanels: () =>
    import(
      "@/components/views/dashboard/members/id/partial/questionnaires/components/VGPQuestionnairePanels.vue"
    ),
  DetailedViewContainer: () =>
    import(
      "@/components/views/dashboard/members/id/partial/questionnaires/DetailedViewContainer.vue"
    ),
};

export default {
  name: "VGP",

  data() {
    return {
      series: [
        {
          name: "series-1",
          data: [
            null,
            null,
            null,
            null,
            2,
            7,
            3,
            1,
            10,
            5,
            6,
            null,
            null,
            null,
            null,
          ],
        },
      ],
      questionnaires: [
        {
          date: "May 28, 2020",
          score: 9,
        },
        {
          date: "September 18, 2020",
          score: 1,
        },
        {
          date: "May 04, 2020",
          score: 12,
        },
        {
          date: "January 12, 2020",
          score: 8,
        },
        {
          date: "September 22, 2020",
          score: 10,
        },
        {
          date: "December 18, 2020",
          score: 6,
        },
        {
          date: "February 14, 2020",
          score: 2,
        },
      ],
      questionnaireQuestions: [
        {
          value: 8,
        },
      ],
    };
  },

  components,
};
</script>